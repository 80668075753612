<template>
  <div id="abort">
    <div class="message">
      <p>Wir können Ihnen in Ihrer Region die Energieberatung über unseren Partner Sunshine anbieten. <br />
        <strong>Alle Informationen finden Sie auf der Seite unseres Partners.</strong></p>
    </div>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateFinishSunshine',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store
    }
  }
}
</script>

